import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { parse } from "query-string";
import { Spinner } from "reactstrap";
import { useLocation } from "@reach/router";
import SignatureCanvas from "react-signature-canvas";
import { useIntl, FormattedMessage, FormattedHTMLMessage, Link, navigate } from "gatsby-plugin-intl";
import { observer } from "mobx-react";

import api from "../../service/api";
import useIsMount from "../../hooks/use-is-mount";
import * as constants from "../../app.constant";
import { sanitizeError } from "../../app.utils";
import alertStore from "../../store/alertStore";
import contractCustomerStore from "../../store/contractCustomerStore";
import AppLayout from "../../components/shared/AppLayout";
import AppNidValidationModal from "../../components/shared/AppNidValidationModal";
import Logo from "../../assets/images/contract-preparation/logo.png";

const defaultInvalidSignatures = {
    signature_1: "",
    signature_2: "",
};

const signaturesSizes = {
    signature_1: {
        width: 250,
        height: 95,
    },
    signature_2: {
        width: 250,
        height: 95,
    },
};

const PageContractPreparation = observer((props) => {
    const intl = useIntl();
    const messages = intl.messages;
    const isMount = useIsMount();
    const location = useLocation();
    const searchParams = parse(location.search);
    const [invalidSignatures, setInvalidSignatures] = useState(defaultInvalidSignatures);
    const [loading, setLoading] = useState(true);
    const signInContractIdRef = useRef();
    const signatureRef_1 = useRef();
    const signatureRef_2 = useRef();

    const onHandleSubmit = async () => {
        const signature_1 = signatureRef_1.current;
        const signature_2 = signatureRef_2.current;

        if (signature_1.isEmpty() || signature_2.isEmpty()) {
            return setInvalidSignatures({
                signature_1: signature_1.isEmpty() ? "invalid-signature" : "",
                signature_2: signature_2.isEmpty() ? "invalid-signature" : "",
            });
        }

        setInvalidSignatures(defaultInvalidSignatures);

        try {
            const payload = {
                signatureDocuments: [
                    {
                        countryCode: "TW",
                        mimeType: "image/png",
                        filename: "signature-1.png",
                        base64EncryptedData: window.btoa(encodeURIComponent(signature_1.toDataURL("base64string"))),
                    },
                    {
                        countryCode: "TW",
                        mimeType: "image/png",
                        filename: "signature-2.png",
                        base64EncryptedData: window.btoa(encodeURIComponent(signature_2.toDataURL("base64string"))),
                    },
                ],
                id: {
                    type: "id",
                    number: signInContractIdRef.current,
                },
            };
            await api.post.contractAccept(searchParams.id, payload);
            navigate(constants.ROUTES.CONTRACT_PREPARATION_SUCCESS);
        } catch (error) {
            alertStore.updateAlertMessage(sanitizeError(error));
        }
    };

    const onHandleReset = () => {
        signatureRef_1.current.clear();
        signatureRef_2.current.clear();
    };

    useEffect(() => {
        if (isMount && !searchParams?.id) {
            navigate(constants.ROUTES.PAGE_NOT_FOUND);
        }
    }, [isMount, searchParams, props]);

    const onHandleValidateContract = async (values, enableSubmitButton) => {
        try {
            signInContractIdRef.current = values.nid;
            const payload = {
                number: values.nid,
                type: "id",
            };
            const response = await api.post.contractValidate(searchParams.id, payload);
            const data = response.data;
            const info = {
                lastName: data?.contact?.lastName,
                gender: data?.contact?.gender,
                id: data?.contact?.ids?.[0]?.number,
                email: data?.contact?.email,
                dateOfBirth: moment(data?.contact?.dateOfBirth).format(constants.DATE_FORMAT),
                phoneNumber: data?.contact?.phoneNumber,
                address: data?.contact.addresses[0].street,
                imei: data?.contractPhones?.new?.serial?.value,
                purchasePrice: `${data?.contractPhones?.new?.recommendedRetailPrice?.currencyCode} ${data?.contractPhones?.new?.recommendedRetailPrice?.amount}`,
                deviceMake: data?.contractPhones?.new?.deviceMake,
                deviceModel: data?.contractPhones?.new?.deviceModel,
            };
            contractCustomerStore.updateContractCustomerDetail(info);
            setLoading(false);
        } catch (error) {
            enableSubmitButton();
        }
    };

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} isBackBtn={false} hasFooter={false}>
            {loading && <AppNidValidationModal onSubmit={onHandleValidateContract} />}
            {loading && (
                <div className="container d-flex justify-content-center pt-5">
                    <Spinner animation="border" />
                </div>
            )}
            {!loading && (
                <div className="app-page page-contract-preparation">
                    <div className="contract-preparation">
                        <header>
                            <div style={{ textAlign: "center" }}>
                                <img src={Logo} alt="hotai" />
                            </div>

                            <p style={{ textAlign: "center" }}>
                                總公司：臺北市仁愛路四段1號 電話：（02）2772-5678 傳真：（02）2772-6666
                                網址:www.msig-mingtai.com.tw
                            </p>
                            <h1 style={{ textAlign: "center", fontSize: 25 }}>
                                <strong>明台產物行動裝置保險(一次交付乙型)要保書</strong>
                            </h1>
                            <p style={{ textAlign: "center" }}>
                                本商品經本公司合格簽署人員檢視其內容業已符合保險精算原則及保險法令，惟為確保權益，基於保險業與消費者衡平對等原則，消費者仍應詳加閱讀保險單條款與相關文件，審慎選擇保險商品。本商品如有虛偽不實或違法情事，應由本公司及負責人依法負責。
                                <br />
                                查詢本公司資訊公開說明文件，請上本公司網站，網址：
                                <a href="https://www.msig-mingtai.com.tw">https://www.msig-mingtai.com.tw</a>,
                                或至本公司索取。
                                <br />
                                <small>費申訴電話：0800-099-080。</small>
                                <small>111.12.30明精字第1110002007號函備查</small>
                            </p>
                        </header>

                        <div className="contract-preparation__body">
                            <div className="e-form">
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "30%" }}>
                                        <b>要保人/被保險人</b>
                                    </div>
                                    <div className="border p-3" style={{ width: "30%" }}>
                                        <b>第 號</b>
                                    </div>
                                    <div className="border p-3" style={{ width: "5%" }}>
                                        <b>電子 保單</b>
                                    </div>
                                    <div className="border p-3" style={{ width: "35%" }}>
                                        <b>
                                            ■本人同意使用【電子保單且不寄送紙本保單】寄送至指定要保人之E-MAIL帳號。若未提供E-MAIL，本公司將以簡訊發送至行動電話門號，通知要保人電子保單下載連結處；仍需紙本保單者，煩請洽本公司申請寄發。
                                        </b>
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "30%" }}>
                                        要保人即
                                        <br />
                                        被保險人
                                    </div>
                                    <div className="border p-3" style={{ width: "10%" }}>
                                        {contractCustomerStore.customerLastName}
                                    </div>
                                    <div className="border p-3" style={{ width: "5%" }}>
                                        性別
                                    </div>
                                    <div className="border p-3" style={{ width: "10%" }}>
                                        {messages[`common.genders.${contractCustomerStore.gender}`]}
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        出生
                                        <br />
                                        年月日
                                    </div>
                                    <div className="border p-3" style={{ width: "10%" }}>
                                        {contractCustomerStore.customerDateOfBirth}
                                    </div>
                                    <div className="border p-3" style={{ width: "10%" }}>
                                        代表人
                                        <br />
                                        負責人
                                    </div>
                                    <div className="border p-3" style={{ width: "10%" }}></div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "30%" }}>
                                        身分證字號/統一編號/護照號碼
                                    </div>
                                    <div className="border p-3" style={{ width: "10%" }}>
                                        {contractCustomerStore.customerNationalId}
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        通訊處地址
                                    </div>
                                    <div className="border p-3" style={{ width: "20%" }}>
                                        {contractCustomerStore.address}
                                    </div>
                                    <div
                                        className="e-form__container"
                                        style={{ width: "25%", flexDirection: "column" }}>
                                        <div className="e-form__container">
                                            <div className="border p-3" style={{ flex: 1 }}>
                                                電話
                                            </div>
                                            <div className="border p-3" style={{ flex: 2 }}>
                                                {contractCustomerStore.phoneNumber}
                                            </div>
                                        </div>
                                        <div className="e-form__container">
                                            <div className="border p-3" style={{ flex: 1 }}>
                                                E-MAIL
                                            </div>
                                            <div className="border p-3" style={{ flex: 2 }}>
                                                {contractCustomerStore.email}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "30%" }}>
                                        行動裝置廠牌
                                    </div>
                                    <div className="border p-3" style={{ width: "10%" }}>
                                        SAMSUNG
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        行動裝置型號
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        {contractCustomerStore.deviceModel}
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        行動裝置空機價
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        {contractCustomerStore.purchasePrice}
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "30%" }}>
                                        產品識別碼(IMEI或SN碼)
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        {contractCustomerStore.imei}
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        指定維修中心
                                    </div>
                                    <div className="border p-3" style={{ width: "40%" }}>
                                        原廠指定維修中心
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "30%" }}>
                                        保險期間
                                    </div>
                                    <div className="border p-3" style={{ width: "70%", textAlign: "center" }}>
                                        自民國&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;日&nbsp;&nbsp;&nbsp;時起至民國&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;日&nbsp;&nbsp;&nbsp;時止
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "35%" }}>
                                        承 保 範 圍
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        保 險 金 額
                                    </div>
                                    <div className="border p-3" style={{ width: "10%" }}>
                                        自 負 額
                                    </div>
                                    <div className="border p-3" style={{ width: "20%" }}>
                                        保 險 費
                                    </div>
                                    <div className="border p-3" style={{ width: "20%" }}>
                                        適用附加條款
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "35%" }}>
                                        保險期間內累計最高賠償次數
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        1次原機維修或置換
                                    </div>
                                    <div className="border p-3" style={{ width: "10%" }}>
                                        2000 元
                                    </div>
                                    <div className="border p-3" style={{ width: "20%" }}>
                                        本保費由三星支付
                                    </div>
                                    <div className="border p-3" style={{ width: "20%" }}></div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "100%" }}>
                                        目前是否向其他保險公司投保同類保險? □是
                                        ■否，如有，請詳述其保險公司名稱、保險單號碼、保險金額與保險期間：
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "100%" }}>
                                        <b>
                                            本公司保密措施︰對於您的個人資料，我們有嚴格的保密措施，以維護您的隱私權，有關本公司保密措施詳細內容歡迎至本公司網址
                                            ({" "}
                                            <a href="https://www.msig-mingtai.com.tw">
                                                https://www.msig-mingtai.com.tw
                                            </a>{" "}
                                            )查詢。本要保書所填寫各項均詳實無訛，絕無隱匿或偽報情事，並承認本要保書為訂立保險契約之基礎及其一部份。
                                        </b>
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "100%" }}>
                                        聲明事項︰
                                        <br /> 一、本人已審閱並瞭解
                                        貴公司所提供之「投保須知」，另依「產險業履行個人資料保護法告知義務內容」，本人已瞭解
                                        貴公司蒐集、處理及利用本人個人資料之目的及用途。
                                        <br />
                                        二、本人知悉
                                        貴公司得依「個人資料保護法」之相關規定，於特定目的範圍內對要保人或被保險人之個人資料，有蒐集、處理及利用之權利。
                                        <br />
                                        此致 明台產物保險股份有限公司
                                        <br />
                                        <div style={{ marginTop: 30, textAlign: "right" }}>
                                            <p>要保人簽章：</p>
                                            <p>未成年人其法定代理人簽章</p>
                                            <div
                                                className={`contract-preparation__signatures-placeholder ${invalidSignatures.signature_1}`}
                                                style={{ marginTop: 30, marginBottom: 30, marginLeft: "auto" }}>
                                                <SignatureCanvas
                                                    ref={signatureRef_1}
                                                    penColor="black"
                                                    canvasProps={signaturesSizes.signature_1}
                                                />
                                            </div>
                                            <p>
                                                年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "100%", display: "flex" }}>
                                        <div className="p-1" style={{ flex: 1 }}>
                                            <b>
                                                瞭解要保人及被保險人之需求及其適合度分析評估、招攬人員報告書(財、保險)
                                            </b>
                                            <p>
                                                要保人：______{contractCustomerStore.customerLastName}______ 被保險人： ______
                                                {contractCustomerStore.customerLastName}______
                                            </p>
                                            <p>國籍：■本國籍□外國籍_____________ 國籍：■本國籍□外國籍_____________</p>
                                            <p>職業：■一般職業□註一所列職業職業：■一般職業□註一所列職業</p>
                                            <p>法人負責人：_____________法人負責人：_____________</p>
                                            <p>
                                                法人註冊地：□本國□外國_____________法人註冊地：□本國□外國_____________
                                            </p>
                                            <p>
                                                （如為外國請填寫國別）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（如為外國請填寫國別）
                                            </p>
                                            <p>
                                                客※屬性：■非專業客※□專業客※投保險種：行動裝置螢幕意外損失維修費用補償保
                                            </p>
                                            <p>(詳註三) 要保人與被保險人關係：_____________■本人</p>
                                            <p>
                                                <b>一、客、屬性（請逐一確認）</b>
                                            </p>
                                            <p>
                                                1.招攬人員已充分瞭解要保人及被保險人之基本資料....................................■
                                            </p>
                                            <p>
                                                2.招攬人員已瞭解要保人之投保條件、投保目的及需求程度，並交由核保人員進行相關核保程序....................................................................................■
                                            </p>
                                            <p>
                                                3.招攬人員已瞭解要保人及被保險人之※動電話號碼、電子郵件信箱或其他經主管機關認可足資傳遞電子文件之※絡方式(保險契約係以電子保單型式出單者適用)
                                                ..........................................................................................■
                                            </p>
                                            <p>
                                                4.招攬人員已使客※瞭解所交保費係用以購買保險商品.................................■
                                            </p>
                                            <p>
                                                5.招攬人員已使客※瞭解其投保之險種、保額與保費支出與其實際需求是否相當■
                                            </p>
                                            <p>
                                                6.招攬人員已瞭解客※對於匯率風險之承受能力(購買以外幣收付之非投資型商品適用)
                                                .............................................................................................□
                                            </p>
                                            <p>
                                                7.招攬人員已充分瞭解客※繳交保險費之資金來源為□工作或營業收入/□存款/■其他由三星支付
                                            </p>
                                            <p>
                                                <b>保人之需求與投保目的（請選擇勾選）</b>
                                            </p>
                                            <p>
                                                1.為個（法）人之財※及利益作風險規劃...................................................■
                                            </p>
                                            <p>
                                                2.為個（法）人依法應負之賠償責任作風險規劃..........................................□
                                            </p>
                                        </div>
                                        <div className="p-1" style={{ flex: 1 }}>
                                            <p>
                                                3.為個（法）人或團體所屬員工可能承受之傷害作風險規劃...........................□
                                            </p>
                                            <p>4.其他（請※明）__________________________</p>
                                            <p>
                                                <b>三、業務報告（請逐一確認）</b>
                                            </p>
                                            <p>
                                                1.招攬人員已向要保人※明對於本保險契約（含附加條款或附加保險）之權利行使、變更、解除及終止之方式........................................................................■
                                            </p>
                                            <p>
                                                2.招攬人員已向要保人※明承保公司對於本保險契約之（含附加條款或附加保險）權利、義務及責任....................................................................................■
                                            </p>
                                            <p>
                                                3.招攬人員已向要保人※明其應負擔之保險費以及毋須負擔違約金及其他費用...■
                                            </p>
                                            <p>
                                                4.招攬人員已向要保人※明本保險依法受保險安定基金之保障........................■
                                            </p>
                                            <p>
                                                5.招攬人員已向要保人※明承保公司因本商品所生紛爭之處理及申訴管道.........■
                                            </p>
                                            <p>
                                                註一：律師、會計師、公證人，或是其合夥人或受※人。軍火商、不動※經紀人。當鋪業、融資從業人員。寶石及貴金屬交易商。藝術品/骨董交易商、拍賣公司。基金會、協會/寺廟、教會從業人員。博弈※業/公司。匯款公司、外幣兌換所。外交人員、大使館、辦事處。虛擬貨幣的發行者或交易商
                                            </p>
                                            <p>
                                                註二：本報告書之部分問項係依洗錢防制法相關法令執行確認客※身分措施，故請招攬人員於建立業務關係時向客※妥為※明。
                                            </p>
                                            <p>
                                                註三：專業客※：要保人或被保險人符合以下條件之一者：(1)依金融消費者保護法第四條第二項授權規定之專業投資機構：國※外之銀行業、證券業、期貨業、保險業(不包括保險代理人、保險經紀人及保險公證人)、基金管理公司及政府投資機構；國※外之政府基金、退休基金、共同基金、單位信託及金融服務業依證券投資信託及顧問法、期貨交易法或信託業法經理之基金或接受金融消費者委任交付或信託移轉之委託投資資※；其他經主管機關認定之機構。
                                            </p>
                                            <p>
                                                (2)要保人或被保險人為法人，接受本公司提供保險商品或服務時最近一期之財務報告總資※達新臺幣五千萬元以上。
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        核定
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        保經代簽署章
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        通路
                                    </div>
                                    <div className="border p-3" style={{ width: "15%" }}>
                                        經手人
                                    </div>
                                    <div className="border p-3" style={{ width: "20%" }}>
                                        業務員簽名/登錄字號
                                    </div>
                                    <div className="border p-3" style={{ width: "20%" }}>
                                        服務人
                                    </div>
                                </div>
                                <div className="e-form__container">
                                    <div className="border p-3" style={{ width: "100%" }}>
                                        履行個人資料保護法
                                        <br />
                                        蒐集、處理及利用個人資料告知※同意書
                                        <p>
                                            保特保險經紀人股份有限公司（以下稱保特保經）、台灣三星電子股份有限公司(以下稱台灣三星)，以及協力廠商台灣保特科技有限公司(以下稱台灣保特科技)，為保險業務之合作、提供客※※品諮詢、維修及延長保固業務等，依據個人資料保護法（以下稱個資法）第8條第1項規定（如為間接蒐集之個人資料則為第9條第1項），向台端告知。保特保經、台灣三星及台灣保特科技個人資料告知事項如下，敬請台端詳※：
                                        </p>
                                        <p>蒐集之目的：</p>
                                        <p>財※保險 (五) 行銷</p>
                                        <p>品諮詢 (六) 消費者、客※管理與服務</p>
                                        <p>
                                            品維修 (七) 其他經營合於營業登記項目或組織章程所定之業務長保固相關業務服務
                                        </p>
                                        <p>二、蒐集之個人資料類別：</p>
                                        <p>
                                            依主管機關公告之個人資料類別（包括但不限於載於各項聲請文件之客※、其代理人及法定代理人之姓名、身分證統一編號、聯絡方式等個人資料）
                                        </p>
                                        <p>三、個人資料來源（個人資料非由當事人提供，而為間接蒐集之情形適用）</p>
                                        <p>（一）要保人/被保險人；</p>
                                        <p>（二）司法警憲機關、委託協助處理理賠之公證人或機構；</p>
                                        <p>（三）當事人之法定代理人、輔助人；</p>
                                        <p>
                                            （四）與第三人共同行銷、交互運用客※資料、合作推廣等關係、或於保特保經、台灣三星及台灣保特科技各項業務※所委託往來之第三人。
                                        </p>
                                        <p>四、個人資料利用之期間、地區、對象、方式：</p>
                                        <p>（一）期間：因執行業務所必須及依法令規定應為保存之期間。</p>
                                        <p>
                                            （二）對象：保特保經、台灣三星及台灣保特科技、業務委外機構、其他與保特保經、台灣三星及台灣保特科技合作推廣之對象。
                                        </p>
                                        <p>
                                            （三）地區：上述對象所在之地區及因辦理保險相關與其他附屬業務包括但不限於資訊蒐集、傳輸、儲存所需要之第三方地區或國家
                                        </p>
                                        <p>（四）方式：合於法令規定之利用方式。</p>
                                        <p>
                                            五、依據個資法第3條規定，台端就保特保經、台灣三星及台灣保特科技保有之個人資料得行使之權利及方式：
                                        </p>
                                        <p>（一）得向保特保經、台灣三星及台灣保特科技行使之權利：</p>
                                        <p>1.向保特保經、台灣三星及台灣保特科技保經※詢、請求※覽或請求製給複製本。</p>
                                        <p>2.向保特保經、台灣三星及台灣保特科技請求補充或更正。</p>
                                        <p>3.向保特保經、台灣三星及台灣保特科技請求停止蒐集、處理或利用及請求刪除。</p>
                                        <p>（二）行使權利之方式：以書面或其他日後可供證明之方式。</p>
                                        <p>
                                            六、台端不提供個人資料所致權益之影響（個人資料由當事人直接蒐集之情形適用）：台端若未能提供相關個人資料時，保特保經、台灣三星及台灣保特科技將可能延後或無法進行必要之審核及處理作業，因此可能無法提供台端相關服務。
                                        </p>
                                        <p>
                                            ---------------------------------------------------------------------------------
                                        </p>
                                        <p>
                                            本人同意保特保經及其關係企業、合作推廣之對象、台灣三星及其合作之門市經銷商、台灣保特科技及其合作之維修中心等，基於保險、行銷、※品服務諮詢或維修服務之目的，得蒐集本人之資料，並得依據本人提供之資料與本人聯絡，或提供行銷資訊等相關服務，並得就本人之個人資料進行交互運用，包含但不限於帳務處理、行銷推廣、客※管理等。
                                        </p>
                                        <p>
                                            本人同意成為保特保經之會員，保特保經得於行銷之目的範圍※蒐集、處理及利用本人個人資料，並得為提供會員優惠權益、行銷活動與最新商品訊息等相關行銷目的使用。
                                        </p>
                                        <div className="contract-preparation__signatures">
                                            <div className="contract-preparation__signatures-wrapper">
                                                <div
                                                    className={`contract-preparation__signatures-placeholder ${invalidSignatures.signature_2}`}>
                                                    <SignatureCanvas
                                                        ref={signatureRef_2}
                                                        penColor="black"
                                                        canvasProps={signaturesSizes.signature_2}
                                                    />
                                                </div>
                                                <p>同意人：__________________________________ (簽章)</p>
                                            </div>
                                        </div>
                                        <p style={{ textAlign: "center" }}>
                                            中華民國 年{" 　         "}月{" 　         "}日
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(invalidSignatures.signature_1 || invalidSignatures.signature_2) && (
                            <p class="contract-preparation__text">
                                <FormattedHTMLMessage id="contract.required" />
                            </p>
                        )}
                        <p className="btn-container btn-container--center contract-preparation__btn-container">
                            <button className="btn btn-lg btn-outline" type="button" onClick={onHandleReset}>
                                <FormattedMessage id="contract.resetCta" />
                            </button>
                            <button className="btn btn-lg btn-primary" type="button" onClick={onHandleSubmit}>
                                <FormattedMessage id="contract.submitCta" />
                            </button>
                        </p>
                        <p>
                            依照《保險經紀人管理規則》，依主管機關規定之適用範圍及內容提供之書面分析報告供您參考：<Link to={constants.ROUTES.TNC_REPORT}>點擊此處下載</Link>
                        </p>
                    </div>
                </div>
            )}
        </AppLayout>
    );
});

export default PageContractPreparation;
