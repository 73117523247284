import React from "react";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";
import { useFormik } from "formik";
import * as Yup from "yup";

import InputField from "../form/input-field";

const AppNidValidationModal = (props) => {
    const intl = useIntl();
    const messages = intl.messages;
    const error = messages["contract.form.nationalIdField.required"];

    const formik = useFormik({
        initialValues: {
            nid: "",
        },
        validationSchema: Yup.object({
            nid: Yup.string().required(error),
        }),
        onSubmit: (values, action) => {
            action.setSubmitting(true);
            props.onSubmit(values, () => {
                action.setSubmitting(false);
                formik.setFieldError("nid", error);
            });
        },
    });

    return (
        <div className="app-nid-validation-modal">
            <div className="nid-validation-modal">
                <div className="nid-validation-modal__body">
                    <div className="nid-validation-modal__careplus-icon" />

                    <div className="nid-validation-modal__logo" />

                    <p className="nid-validation-modal__header text-bold">
                        <FormattedMessage id="contract.title" />
                    </p>

                    <form
                        className="nid-validation-modal__form"
                        noValidate
                        autoComplete="off"
                        onSubmit={formik.handleSubmit}>
                        <div className="nid-validation-modal__content">
                            <p className="nid-validation-modal__info">
                                <FormattedHTMLMessage id="contract.description" />
                            </p>

                            <div className="nid-validation-modal__field">
                                <InputField
                                    label={messages["contract.form.nationalIdField.label"]}
                                    placeholder={messages["contract.form.nationalIdField.placeholder"]}
                                    name="nid"
                                    formik={formik}
                                />
                            </div>
                        </div>

                        <div className="nid-validation-modal__footer">
                            <div className="btn-container">
                                <button className="btn btn-lg btn-primary" type="submit" disabled={formik.isSubmitting}>
                                    <FormattedMessage id="contract.form.submitCta" />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="nid-validation-modal__backdrop" />
            </div>
        </div>
    );
};

export default AppNidValidationModal;
